import BankInfo from 'components/BankInfo/BankInfo';
import PaidUntil from 'components/PaidUntil/PaidUntil';
import Profile from 'components/Profile/Profile';
import QrCode from 'components/QrCode/QrCode';
import React from 'react';

function Payments() {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <QrCode />
        <div style={{ marginTop: '10px' }}>
          <PaidUntil />
          <div style={{ marginTop: '10px' }}>
            <Profile />
          </div>
          <div style={{ marginTop: '10px' }}>
            <BankInfo />
          </div>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <p>Podrobnosti o fungovani systemu</p>
          <ul>
            <li>
              <b>Neuhrazení do 14.</b> dne v měsíci představuje{' '}
              <b>pozastavení služeb.</b>
            </li>
            <li>
              <b>Neuhrazení do 24.</b> dne v měsíci dojde k{' '}
              <b>deaktivaci účtu</b>.
            </li>
            <li>
              Reaktivace účtu bude provedena ručně <b>po dohodě</b> a{' '}
              <b>uhrazení reaktivačního příspěvku</b>.
            </li>
            <li>
              Výpadek služby nebo její zrušení není důvodem k vrácení
              dobrovolného příspěvku.
            </li>
            <li>Vždy je potřeba dodržet přesnou částku.</li>
            <li>Výchozí částka je vždy příspěvek na jeden měsíc.</li>
            <li>
              Částku je možno libovolně násobit pro požadovaný počet měsíců.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Payments;
