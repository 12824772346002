import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const LoaderView = () => (
  <div>
    <TailSpin width={'100%'} color="#D81F26" />
  </div>
);

export default LoaderView;
