import React from 'react';

type BackgroundProps = {
  children: React.ReactNode;
};

function Background({ children }: BackgroundProps) {
  return (
    <div
      style={{
        paddingTop: '1em',
        backgroundColor: 'rgba(0,0,0,0.8)',
      }}
    >
      {children}
    </div>
  );
}

export default Background;
