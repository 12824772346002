import React from 'react';

type LogoProps = {
  [x: string]: any;
};

function Logo({ ...restProps }: LogoProps) {
  return (
    <div>
      <a href="/" {...restProps}>
        <img className="logo" src="/images/logo.png" alt="Logo" />
      </a>
    </div>
  );
}

export default Logo;
