import React from 'react';
import './LoadingStyles.css';

type LoadingVideoModal = {
  children: React.ReactNode;
};

function LoadingVideoModal({ children }: LoadingVideoModal) {
  return (
    <div className="loading-video-wrapper">
      <div className="loading-video-modal">
        <div className="loading-video-container">
          <video autoPlay src="videos/aRzG2Xo.mp4" loop />
          <div className="loading-video-text-overlay">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default LoadingVideoModal;
