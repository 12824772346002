import React from 'react';

type HeaderWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

function HeaderWrapper({
  children,
  className,
  ...restProps
}: HeaderWrapperProps) {
  return (
    <header className={className!} {...restProps}>
      {children}
    </header>
  );
}

export default HeaderWrapper;
