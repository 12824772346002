import { MessageDescriptor } from 'react-intl';

export const messages: { [name: string]: MessageDescriptor } = {
  title: {
    id: 'profile.title',
    defaultMessage: 'Profile',
  },
  username: {
    id: 'profile.username',
    defaultMessage: 'Username',
  },
  name: {
    id: 'profile.name',
    defaultMessage: 'Name',
  },
  mail: {
    id: 'profile.mail',
    defaultMessage: 'Mail',
  },
};
