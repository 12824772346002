import React from 'react';
import HeaderWrapper from 'components/Header/HeaderWrapper';
import NavBarCompound from 'compounds/NavBarCompoud';
import Background from 'components/Background/Background';

interface NavigationPageProps {
  children: React.ReactNode;
}

function NavigationPage({ children }: NavigationPageProps) {
  return (
    <>
      <HeaderWrapper className="header-wrapper-browse">
        <NavBarCompound />
      </HeaderWrapper>
      <Background>{children}</Background>
    </>
  );
}

export default NavigationPage;
