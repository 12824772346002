import { useQrCode } from 'api/apiHooks';
import React from 'react';

function QrCode() {
  const { data, error } = useQrCode();
  if (error) return <div>Failed to load image</div>;
  if (!data) return <div>Loading...</div>;

  const imageUrl = URL.createObjectURL(data);
  return (
    <img
      style={{ maxWidth: '350px', padding: '10px', objectFit: 'contain' }}
      src={imageUrl}
      alt="QR Code"
    />
  );
}

export default QrCode;
