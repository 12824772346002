import { MessageDescriptor } from 'react-intl';

export const messages: { [name: string]: MessageDescriptor } = {
  month: {
    id: 'payment-list.month',
    defaultMessage: 'Month',
  },
  year: {
    id: 'payment-list.year',
    defaultMessage: 'Year',
  },
  created: {
    id: 'payment-list.created',
    defaultMessage: 'Created',
  },
};
