import axios, { AxiosRequestConfig } from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

export const serverPaymentUrl = 'https://pay.blackzone.site/api';

const instance = axios.create({
  baseURL: serverPaymentUrl,
});

type AxiosInterceptorProps = {
  children: React.ReactNode;
};

function AxiosInterceptor({ children }: AxiosInterceptorProps) {
  const { user, signinRedirect } = useAuth();
  const [isSet, setIsSet] = useState(false);
  useEffect(() => {
    const apiInterceptor = instance.interceptors.request.use(
      (config) => {
        config.headers['X-Device-Id'] = 'Manager';
        config.headers['X-Device-Name'] = 'TWFuYWdlcg==';
        if (user) {
          if (user.expired) {
            signinRedirect();
          }
          config.headers['Authorization'] = `Bearer ${user.access_token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    setIsSet(true);
    return () => {
      instance.interceptors.request.eject(apiInterceptor);
    };
  }, [user, user?.access_token]);

  return isSet && children;
}

export class Api {
  GetItems = <T,>(url: string, data?: AxiosRequestConfig): Promise<T[]> =>
    instance.get(url, data).then((res) => res.data.items);
  Get = <T,>(url: string, data?: AxiosRequestConfig): Promise<T> =>
    instance.get(url, data).then((res) => res.data);
  GetBlob = <T,>(url: string): Promise<T> =>
    instance.get(url, { responseType: 'blob' }).then((res) => res.data);
  Post = <T,>(url: string, data: any): Promise<T> =>
    instance.post(url, data).then((res) => res.data);
  Put = (url: string) => instance.put(url).then((res) => res.data);
  Patch = (url: string) => instance.patch(url).then((res) => res.data);
  PutData = <T,>(url: string, data: any): Promise<T> =>
    instance.put(url, data).then((res) => res.data);
  Del = (url: string) => instance.delete(url).then((res) => res.data);
}

export { AxiosInterceptor };
