import React, { CSSProperties } from 'react';

type NavBarProps = {
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
};

function NavBar({ children, className, style, ...restProps }: NavBarProps) {
  return (
    <nav className={className!} style={style!} {...restProps}>
      {children}
    </nav>
  );
}

export default NavBar;
