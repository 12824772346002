import React from 'react';
import Payments from 'components/Payments/Payments';
import NavigationPage from './NavigationPage';

function HomePage() {
  return (
    <NavigationPage>
      <Payments />
    </NavigationPage>
  );
}

export default HomePage;
