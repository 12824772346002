import React from 'react';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

type IntlProviderProps = {
  children: React.ReactNode;
};

export function getLocale() {
  let locale = navigator.languages[0].substring(0, 2);

  if (!['en', 'cs', 'sk'].includes(locale)) {
    locale = 'en';
  }

  return locale;
}

function IntlProviderWrapper({ children }: IntlProviderProps) {
  const [messages, setMessages] = useState<Record<string, string>>();

  const locale = getLocale();

  function importMessages(locale: string) {
    switch (locale) {
      case 'cs':
        return import('../translations/cs.json');
      default:
        return import('../translations/en.json');
    }
  }

  useEffect(() => {
    importMessages(locale).then((data) => setMessages(data.default));
  }, [locale]);
  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}

export default IntlProviderWrapper;
