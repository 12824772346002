import HeaderLink from 'components/Header/HeaderLink';
import Logo from 'components/Header/Logo';
import NavBar from 'components/Header/NavBar';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { Link, useLocation } from 'react-router-dom';
import { messages } from './messages';

function NavBarCompound() {
  const auth = useAuth();
  const location = useLocation();

  return (
    <div className="navbar">
      <Logo />
      <div className="navbar-items">
        <NavBar className="navbar-browse">
          <HeaderLink
            className={
              location.pathname === '/' ? 'header-link-bold' : 'header-link'
            }
          >
            <Link to={'/'}>
              <FormattedMessage {...messages.home} />
            </Link>
          </HeaderLink>
          <HeaderLink
            className={
              location.pathname === '/payments'
                ? 'header-link-bold'
                : 'header-link'
            }
          >
            <Link to={'/payments'}>
              <FormattedMessage {...messages.payments} />
            </Link>
          </HeaderLink>
        </NavBar>
        <NavBar className="navbar-browse-right">
          <HeaderLink
            className={'header-link'}
            onClick={() => auth.signoutRedirect()}
          >
            <FormattedMessage {...messages.logout} />
          </HeaderLink>
        </NavBar>
      </div>
    </div>
  );
}

export default NavBarCompound;
