import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'react-oidc-context';
import { messages } from './messages';

function Profile() {
  const auth = useAuth();
  const profile = auth.user?.profile;

  return (
    <>
      <h3 style={{ margin: '2px 0px' }}>
        <FormattedMessage {...messages.title} />
      </h3>
      <div className="table">
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.username} />:
          </div>
          <div className="cell cell-start">{profile?.preferred_username}</div>
        </div>
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.name} />:
          </div>
          <div className="cell cell-start">{profile?.name}</div>
        </div>
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.mail} />:
          </div>
          <div className="cell cell-start">{profile?.email}</div>
        </div>
      </div>
    </>
  );
}

export default Profile;
