import LoadingVideoModal from 'components/LoadingModal/LoadingVideoModal';
import React, { useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';

type Props = {
  children: React.ReactNode;
};

function Auth({ children }: Props) {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (auth.isLoading) {
    return <LoadingVideoModal>{'Signing you in/out...'}</LoadingVideoModal>;
  }

  if (!auth.isAuthenticated) {
    return <LoadingVideoModal>{'Unable to log in'}</LoadingVideoModal>;
  }

  return children;
}

export default Auth;
