import { MessageDescriptor } from 'react-intl';

export const messages: { [name: string]: MessageDescriptor } = {
  title: {
    id: 'bankinfo.title',
    defaultMessage: 'Payment Information',
  },
  account: {
    id: 'bankinfo.account',
    defaultMessage: 'Account',
  },
  amount: {
    id: 'bankinfo.amount',
    defaultMessage: 'Amount',
  },
  variableSymbol: {
    id: 'bankinfo.variable-symbol',
    defaultMessage: 'Variable Symbol',
  },
};
