import React from 'react';
import PaymentList from 'components/PaymentList/PaymentList';
import NavigationPage from './NavigationPage';

function PaymentPage() {
  return (
    <NavigationPage>
      <PaymentList />
    </NavigationPage>
  );
}

export default PaymentPage;
