import { MessageDescriptor } from 'react-intl';

export const messages: { [name: string]: MessageDescriptor } = {
  home: {
    id: 'navbar.home',
    defaultMessage: 'Home',
  },
  payments: {
    id: 'navbar.payments',
    defaultMessage: 'Payments',
  },
  logout: {
    id: 'navbar.logout',
    defaultMessage: 'LogOut',
  },
};
