import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import Auth from 'Auth';
import { AxiosInterceptor } from 'api/api';
import IntlProviderWrapper from 'providers/IntlProvider';

const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);

const oidcConfig = {
  authority: 'https://account.blackzone.site/realms/blackzone/',
  client_id: 'payment',
  silent_redirect_uri: window.location.origin,
  redirect_uri: window.location.origin,
  onSigninCallback: (_user: User | void) => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  post_logout_redirect_uri: window.location.origin,
};

root.render(
  <IntlProviderWrapper>
    <AuthProvider {...oidcConfig}>
      <Auth>
        <AxiosInterceptor>
          <Router>
            <App />
          </Router>
        </AxiosInterceptor>
      </Auth>
    </AuthProvider>
  </IntlProviderWrapper>,
);
