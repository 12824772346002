import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Api } from './api';

const api = new Api();

export function useQrCode() {
  return useSWR<Blob>([`/Payment/Generate`], ([url]) => api.GetBlob(url), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
}

export function usePayments(pageSize: number) {
  return useSWRInfinite(
    (index, prev) => [`/Payment/List?page=${index + 1}&pageSize=${pageSize}`],
    ([url]) => api.GetItems<PaymentInfo>(url),
  );
}

export function usePaidUntil() {
  return useSWR([`/Payment/Until`], ([url]) => api.Get<PaymentInfo>(url));
}

export function useBankInfo() {
  return useSWR([`/Payment/PaymentInfo`], ([url]) => api.Get<BankInfo>(url));
}
