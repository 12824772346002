import { useBankInfo, usePaidUntil } from 'api/apiHooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';

function BankInfo() {
  const { data, error } = useBankInfo();
  if (error) return <div>Failed to load</div>;
  if (!data) return <div>Loading...</div>;
  return (
    <>
      <h3 style={{ margin: '2px 0px' }}>
        <FormattedMessage {...messages.title} />
      </h3>
      <div className="table">
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.account} />:
          </div>
          <div className="cell cell-start">
            {data.accountNumber} / {data.bankCode}
          </div>
        </div>
        <div className="row">
          <div className="cell heading">IBAN:</div>
          <div className="cell cell-start">{data.iban}</div>
        </div>
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.variableSymbol} />:
          </div>
          <div className="cell cell-start">{data.variableSymbol}</div>
        </div>
        <div className="row">
          <div className="cell heading">
            <FormattedMessage {...messages.amount} />:
          </div>
          <div className="cell cell-start">{data.monthAmount} CZK</div>
        </div>
      </div>
    </>
  );
}

export default BankInfo;
