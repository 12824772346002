import { MessageDescriptor } from 'react-intl';

export const messages: { [name: string]: MessageDescriptor } = {
  never: {
    id: 'paiduntil.never',
    defaultMessage: 'Not payed or never been payed!',
  },
  expire: {
    id: 'paiduntil.expire',
    defaultMessage: 'Account expire after {month} month of year {year}.',
  },
};
