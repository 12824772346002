import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from 'pages/HomePage';
import PaymentPage from 'pages/PaymentPage';

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/payments" element={<PaymentPage />} />
    </Routes>
  );
}

export default App;
