import React from 'react';

type HeaderLinkProps = {
  children: React.ReactNode;
  className: string;
  onClick?: () => void;
};

function HeaderLink({
  children,
  className,
  onClick,
  ...restProps
}: HeaderLinkProps) {
  return (
    <div
      onClick={onClick!}
      className={className ? className + ' header-link' : 'header-link'}
    >
      <div {...restProps}>{children}</div>
    </div>
  );
}

export default HeaderLink;
