import { usePaidUntil } from 'api/apiHooks';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from './messages';

function PaidUntil() {
  const { data } = usePaidUntil();
  if (!data) {
    return (
      <div>
        <FormattedMessage {...messages.never} />
      </div>
    );
  }
  return (
    <div>
      <FormattedMessage
        {...messages.expire}
        values={{ month: data.month, year: data.year }}
      />
    </div>
  );
}

export default PaidUntil;
