import { usePayments } from 'api/apiHooks';
import LoaderView from 'components/Loader/LoaderView';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-swr-infinite-scroll';
import { messages } from './messages';
function PaymentList() {
  const swr = usePayments(10);

  return (
    <table key={'paymentList'} style={{ margin: '0 auto' }}>
      <tbody>
        <tr>
          <th>Id</th>
          <th>
            <FormattedMessage {...messages.month} />
          </th>
          <th>
            <FormattedMessage {...messages.year} />
          </th>
          <th>
            <FormattedMessage {...messages.created} />
          </th>
        </tr>
        <InfiniteScroll
          swr={swr}
          loadingIndicator={<LoaderView />}
          isReachingEnd={(swr) =>
            swr.data?.[0]?.length === 0 ||
            swr.data?.[swr.data?.length - 1]?.length < 25
          }
        >
          {(response: PaymentInfo[]) =>
            response?.map((item) => {
              const today = new Date();

              const createdOn = new Date(item.createdOn + 'Z');
              const isCreatedOnToday =
                today.toDateString() === createdOn.toDateString();
              return (
                <tr key={item.id}>
                  <td style={{ padding: '10px' }}>{item.id}</td>
                  <td style={{ padding: '10px' }}>{item.month}</td>
                  <td style={{ padding: '10px' }}>{item.year}</td>
                  <td style={{ padding: '10px' }}>
                    {isCreatedOnToday
                      ? createdOn.toLocaleTimeString()
                      : createdOn.toLocaleString()}
                  </td>
                </tr>
              );
            })
          }
        </InfiniteScroll>
      </tbody>
    </table>
  );
}

export default PaymentList;
